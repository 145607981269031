import * as _ from 'lodash-es'

export const toLocalPhoneNumber = (globalPhoneNumber: string) => {
	if (!globalPhoneNumber.startsWith('+')) {
		return globalPhoneNumber
	}

	const fullPhoneNumber = `0${globalPhoneNumber.slice(3)}`

	return fullPhoneNumber.replace(/^(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
}

export function convertPhoneNumberFormat (value: string) {
	return (
		value.substring(0, 3) +
		'-' +
		value.substring(3, 7) +
		'-' +
		value.substring(7, 11)
	)
}

export const convertPhoneNumber = _.flow(
	toLocalPhoneNumber,
	convertPhoneNumberFormat,
)

export function truncateText (
	input: string,
	maxLength: number,
	truncateSize = 3,
) {
	if (!input) return ''
	return input.length > maxLength
		? input.substring(0, maxLength - truncateSize) + '...'
		: input
}

export function formatSeconds (seconds: number) {
	const hour = Math.floor(seconds / 3600)
	const minute = Math.floor((seconds % 3600) / 60)
	const second = Math.floor(seconds % 60)

	return `${hour.toString().padStart(2, '0')}:${minute
		.toString()
		.padStart(2, '0')}:${second.toString().padStart(2, '0')}`
}
